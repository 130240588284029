import EventEmitter from 'events'

let acceptedActions = new Set([
  'getExternalId_response',
  'fetchRelateOptions_response',
  'clickToCall',
  'integrationStatusChanged',
])

export default class GenericCTI {
  constructor (extraActions = []) {
    this.events = new EventEmitter()
    acceptedActions = new Set([
      ...acceptedActions,
      ...extraActions
    ])
    window.addEventListener('message', this.handlePostMessages())
  }

  handlePostMessages () {
    return (event) => {
      if (event.data && event.data.action) {
        if (acceptedActions.has(event.data.action)) {
          this.events.emit(event.data.action, event.data)
        } else {
          console.warn('Unsupported Generic CTI action (' + event.data.action + ').')
        }
      }
    }
  }

  getExternalId (data = {}) {
    return new Promise((resolve, reject) => {
      const rejectTimeout = setTimeout(reject, 5000)
      this.events.on('getExternalId_response', (response) => {
        clearTimeout(rejectTimeout)
        resolve(response.response)
      })
      this._sendMessage({
        action: 'getExternalId',
        data: {
          accountName: data.accountName
        }
      })
    })
  }

  onClickToCall (callback) {
    this.events.on('clickToCall', callback)
  }

  onOpenContact (data) {
    this._sendMessage({
      action: 'openContact',
      data
    })
  }

  onSendData (data) {
    this._sendMessage({
      action: 'sendData',
      data
    })
  }

  hidePopover () {
    this._sendMessage({
      action: 'hide'
    })
  }

  showPopover () {
    this._sendMessage({
      action: 'show'
    })
  }

  applyHeight (height) {
    this._sendMessage({
      action: 'resize',
      data: { height }
    })
  }

  onAgentStatusChanged (data) {
    this._sendMessage({
      action: 'agentStatusChanged',
      data
    })
  }

  onIntegrationStatusChange (callback) {
    this.events.on('integrationStatusChanged', callback)
  }

  onConnect (data) {
    this._sendMessage({
      action: 'connect',
      data
    })
  }

  onDisconnect (data) {
    this._sendMessage({
      action: 'disconnect',
      data
    })
  }

  onLogin () {
    this._sendMessage({
      action: 'login'
    })
  }

  onLogout () {
    this._sendMessage({
      action: 'logout'
    })
  }

  onUserLogoutForced () {
    this._sendMessage({
      action: 'userLogoutForced'
    })
  }

  _sendMessage (message) {
    window.parent.postMessage(message, '*')
  }
}
